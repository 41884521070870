<template>
    <div>
        <!-- <div class="index-wrap" v-if="category==1" style="width: 1200px;margin: 0 auto;">
			<el-carousel height="400px" v-loading="loadingAd">
				<el-carousel-item v-for="item in adList" :key="item.adv_id"><el-image :src="$img(item.adv_image)" fit="cover" @click="$router.pushToTab(item.adv_url.url)" /></el-carousel-item>
			</el-carousel>
		</div>
		
		<div class="index-wrap" v-if="category==2" style="width: 1200px;margin: 0 auto;">
			<el-carousel height="400px" v-loading="loadingAd">
				<el-carousel-item v-for="item in adList" :key="item.adv_id"><el-image :src="$img(item.adv_image)" fit="cover" @click="$router.pushToTab(item.adv_url.url)" /></el-carousel-item>
			</el-carousel>
		</div>
		
		<div class="index-wrap" v-if="category==3" style="width: 1200px;margin: 0 auto;">
			<el-carousel height="400px" v-loading="loadingAd">
				<el-carousel-item v-for="item in adList" :key="item.adv_id"><el-image :src="$img(item.adv_image)" fit="cover" @click="$router.pushToTab(item.adv_url.url)" /></el-carousel-item>
			</el-carousel>
		</div> -->

        <div class="newCategory" :class="[isMobile?'mobile':'pc']" v-loading="loading">
            <!-- <div class="categoryLink">
                <ul id="categoryUl" :class="categoryFixed == true ? 'category-fixed' : ''">
                    <li v-for="(item, index) in goodsCategory" :key="index"
                        :class="index == clickIndex ? 'selected' : ''" @click="changeCate(index, '#category' + index)">
                        <a>
                            <span>{{ item.category_name }}</span>
                        </a>
                    </li>
                </ul>
            </div> -->
            <div v-if="isMobile" style="width:100%;padding:0 10px;box-sizing:border-box;">
                <div  class="items" :class="'items-' + indexL" v-for="(cate1, indexL) in goodsCategory" :key="indexL" v-if="cate1.category_id == currentCategory">
                    <h2>
                        <span @click="() => handleCategoryClick(cate1, true)">{{ cate1.category_name }}</span>
                    </h2>
                    <dl v-for="(cate2, index) in cate1.child_list" :key="index">
                        <dt>
                           <h4> <span @click="() => handleCategoryClick(cate2)">{{ cate2.category_name }}</span></h4>
                        </dt>
                        <dd>
                            <span style="margin-left:10px;" v-for="(cate3, index) in cate2.child_list" :key="index" @click="() => handleCategoryClick(cate3)">{{ cate3.category_name }}</span>
                        </dd>
                    </dl>
                </div>
            </div>
            <div class="categoryCon" v-else>
                <div :id="'category' + indexL" :ref="'category' + indexL" class="items" :class="'items-' + indexL" v-for="(cate1, indexL) in goodsCategory" :key="indexL" v-if="cate1.category_id == currentCategory">
                    <h2>
                        <span @click="() => handleCategoryClick(cate1, true)">{{ cate1.category_name }}</span>
                    </h2>

                    <dl v-for="(cate2, index) in cate1.child_list" :key="index">
                        <dt>
                            <span @click="() => handleCategoryClick(cate2)">{{ cate2.category_name }}</span>
                        </dt>
                        <dd>
                            <span v-for="(cate3, index) in cate2.child_list" :key="index" @click="() => handleCategoryClick(cate3)">{{ cate3.category_name }}</span>
                        </dd>
                        <!-- <div @click="() => toggleIf(indexL)" v-if="index==0" style="cursor: pointer;">Expand+</div> -->
                    </dl>
                </div>
            </div>

            <!-- <div class="categoryCon">
                <div :id="'category' + indexL" :ref="'category' + indexL" class="items" :class="'items-' + indexL"  v-for="(cate1, indexL) in goodsCategory" :key="indexL" v-if="cate1.category_id ==category">
                    <h2>
                        <span @click="() => handleCategoryClick(cate1)">{{ cate1.category_name }}</span>
                    </h2>

                    <dl v-for="(cate2, index) in cate1.child_list" :key="index" v-if="index==0 || cate1.is_open!=0">
                        <dt>
                            <span @click="() => handleCategoryClick(cate2)">{{ cate2.category_name }}</span>
                        </dt>
                        <dd>
                            <span v-for="(cate3, index) in cate2.child_list" :key="index"
                                @click="() => handleCategoryClick(cate3)">{{ cate3.category_name }}</span>
                        </dd>
						<div @click="() => toggleIf(indexL)" v-if="index==0" style="cursor: pointer;">Expand+</div>
                    </dl>
                </div>
            </div> -->

            <div class="empty-wrap" v-if="goodsCategory.length <= 0">
                <div class="ns-text-align">No product classification yet</div>
            </div>
        </div>
        <div style="background-color: #fff; margin: 0 auto" :style="{ width: isMobile ? '100%' : '1200px' }">
            <GoodsListPanel :category-id="category" :category-level="level"></GoodsListPanel>
        </div>
    </div>
</template>

<script>
    import GoodsListPanel from "@/components/GoodsListPanel.vue"
    import { tree } from "@/api/goods/goodscategory"
    import { adList } from "@/api/website"
    import { mapGetters, mapActions } from "vuex"

    export default {
        name: "categorySpec",
        components: {
            GoodsListPanel
        },
        data: () => {
            return {
                loadingAd: true,
                adList: [],
                goodsCategory: [],
                categoryFixed: false,
                clickIndex: 0,
                loading: true,
                currentCategory: "",
                category: "",
                level: ""
            }
        },
        computed: {
            ...mapGetters("device", ["isMobile"]) // 获取 isMobile 状态
        },
        watch: {
            $route(to,from) {
                console.log('to--->,',to)
                 this.category = to.query.category_id || ""
                 this.currentCategory = to.query.category_id || ""
            }
        },
        created() {
            this.category = this.$route.query.category_id || ""
            this.currentCategory = this.$route.query.category_id || ""
            this.level = this.$route.query.level || ""
            this.getGoodsCategory()
            this.getAdList()
        },
        mounted() {
            window.addEventListener("scroll", this.handleScroll)
        },
        methods: {
            toggleIf(index) {
                this.goodsCategory[index].is_open = !this.goodsCategory[index].is_open
            },
            handleCategoryClick(category, judge = false) {
                this.category = category.category_id
                this.level = category.level
                if (judge) this.currentCategory = category.category_id
            },
            getAdList() {
                var key = "NS_PC_PUMP"
                if (this.category == 2) {
                    key = "NS_PC_VALUE"
                } else if (this.category == 3) {
                    key = "NS_PC_ACCESSORIES"
                }
                adList({ keyword: key })
                    .then((res) => {
                        this.adList = res.data.adv_list
                        for (let i = 0; i < this.adList.length; i++) {
                            if (this.adList[i].adv_url) this.adList[i].adv_url = JSON.parse(this.adList[i].adv_url)
                        }
                        this.loadingAd = false
                    })
                    .catch((err) => {
                        this.loadingAd = false
                    })
            },
            // 分类列表
            getGoodsCategory() {
                tree({
                    level: 3,
                    template: 2
                })
                    .then((res) => {
                        if (res.code == 0) {
                            this.goodsCategory = res.data
                            console.log("this.goodsCategory", this.goodsCategory)
                        }
                        this.loading = false
                    })
                    .catch((err) => {
                        this.$message.error(err.message)
                        this.loading = false
                    })
            },
            // 监听滚动条
            handleScroll() {
                var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
                var offsetTop = document.querySelector(".newCategory").offsetTop

                if (scrollTop > offsetTop) {
                    this.categoryFixed = true
                } else {
                    this.categoryFixed = false
                }

                var divTopArr = []
                for (let i = 0; i < this.goodsCategory.length; i++) {
                    var _top = this.$refs["category" + i][0].offsetTop

                    divTopArr.push(_top)
                    var _offset = scrollTop - offsetTop
                    if (_offset < divTopArr[divTopArr.length - 1]) {
                        if (_offset >= divTopArr[i] && _offset < divTopArr[i + 1]) {
                            this.clickIndex = i
                        }
                    } else {
                        this.clickIndex = divTopArr.length - 1
                    }
                }
            },
            // 点击左侧分类
            changeCate(index, obj) {
                this.clickIndex = index
                document.querySelector(obj).scrollIntoView(true)
            }
        },
        destroyed() {
            // 离开该页面需要移除这个监听的事件，不然会报错
            window.removeEventListener("scroll", this.handleScroll)
        }
    }
</script>
<style lang="scss" scoped>
.mobile.newCategory{
    width: 100%;
}
    .newCategory {
        width: $width;
        margin: 0 auto;
        overflow: hidden;
        background-color: #fff;

        .categoryLink {
            padding-top: 10px;
            float: left;
            position: relative;
            width: 210px;

            ul {
                width: 210px;
                padding-top: 20px;
                background-color: #ffffff;

                li {
                    width: 200px;
                    height: 30px;
                    text-align: left;
                    background-color: #f5f5f5;
                    border-radius: 30px;
                    color: #333;
                    line-height: 30px;
                    overflow: hidden;
                    position: relative;
                    cursor: pointer;
                    margin: 0 auto 15px;

                    &.selected {
                        background-color: $base-color;
                        background: -moz-linear-gradient(45deg, $base-color 0%, #ffffff 100%);
                        background: -webkit-gradient(45deg, color-stop(0%, $base-color), color-stop(100%, #ffffff));
                        background: -webkit-linear-gradient(45deg, $base-color 0%, #ffffff 100%);
                        background: -o-linear-gradient(45deg, $base-color 0%, #ffffff 100%);
                        background: -ms-linear-gradient(45deg, $base-color 0%, #ffffff 100%);
                        background: linear-gradient(45deg, $base-color 0%, #ffffff 100%);

                        a {
                            color: #fff;
                        }
                    }

                    a {
                        display: block;
                        margin-left: 30px;
                    }
                }
            }
        }

        .category-fixed {
            position: fixed;
            top: 0;
            z-index: 99;
        }

        .categoryCon {
            float: left;
            padding: 0px 0 60px;
            overflow: hidden;
            width: 1190px;
            position: relative;

            .items {
                padding-left: 40px;

                h2 {
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 40px;
                    margin-top: 30px;
                    border-bottom: 1px solid transparent;
                }

                span {
                    cursor: pointer;
                }

                dl {
                    padding: 15px 0 5px;
                    border-bottom: 1px solid #efefef;
                    overflow: hidden;

                    dt {
                        padding-right: 20px;
                        width: 132px;
                        position: relative;
                        background: #fff;
                        float: left;

                        span {
                            float: left;
                            max-width: 132px;
                            // overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            font-weight: 600;
                        }
                    }

                    dd {
                        float: left;
                        width: 900px;

                        span {
                            color: #666;
                            float: left;
                            padding: 0 12px;
                            margin: 0 0 10px -1px;
                            border-left: 1px solid #e0e0e0;
                            white-space: nowrap;
                        }
                    }
                }
            }
        }
    }
</style>
