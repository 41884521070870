<template>
    <div id="app">
        <transition name="slide"><router-view /></transition>
    </div>
</template>
<script>
    import { mapState, mapActions } from "vuex"
    import http from "@/utils/http"
    export default {
        name: "APP",
        mounted() {
            this.initDevice();
            // this.SET_LANG('vi');
            // this.$i18n.locale = this.$store.state.app.langage
            /*            let lang = localStorage.getItem('lang')
                        lang = 'en_US'
                        console.log("lang: ", lang)
                        if (lang == 'en_US') {
                            this.$i18n.locale = "en"
                        } else {
                            this.$i18n.locale = "zh"
                            this.locale = null
                        }*/
            if (localStorage.getItem("langage")) {
                this.$i18n.locale = localStorage.getItem("langage")
            } else {
                this.$i18n.locale = "en"
                localStorage.setItem("langage", "en")
            }
        },
        methods: {
            ...mapActions('device', ['initDevice']),
           
        }
    }
</script>
<style lang="scss" scoped>
    /*每个页面公共css */
    @import url("assets/styles/iconfont.css");
    @import "assets/styles/main.scss";
</style>
