<template>
    <div>
        <div class="pc-category">
            <!-- <div class="index-wrap" style="width: 1200px;margin: 0 auto;">
                <el-carousel height="400px" v-loading="loadingAd">
                    <el-carousel-item v-for="item in adList" :key="item.adv_id"><el-image :src="$img(item.adv_image)" fit="cover" @click="$router.pushToTab(item.adv_url.url)" /></el-carousel-item>
                </el-carousel>
            </div> -->

            <div class="newCategory" v-loading="loading">
                <!-- <div class="categoryLink">
                    <ul id="categoryUl" :class="categoryFixed == true ? 'category-fixed' : ''">
                        <li v-for="(item, index) in goodsCategory" :key="index"
                            :class="index == clickIndex ? 'selected' : ''" @click="changeCate(index, '#category' + index)">
                            <a>
                                <span>{{ item.category_name }}</span>
                            </a>
                        </li>
                    </ul>
                </div> -->

                <div class="categoryCon">
                    <div :id="'category' + indexL" :ref="'category' + indexL" class="items" :class="'items-' + indexL"
                         v-for="(cate1, indexL) in goodsCategory" :key="indexL">
                        <h2>
                            <span @click="() => handleCategoryClick(cate1)">{{ cate1.category_name }}</span>
                        </h2>

                        <dl v-for="(cate2, index) in cate1.child_list" :key="index" v-if="index==0 || cate1.is_open!=0">
                            <dt>
                                <span @click="() => handleCategoryClick(cate2)">{{ cate2.category_name }}</span>
                            </dt>
                            <dd>
                            <span v-for="(cate3, index) in cate2.child_list" :key="index"
                                  @click="() => handleCategoryClick(cate3)">{{ cate3.category_name }}</span>
                            </dd>
                            <div @click="() => toggleIf(indexL)" v-if="index==0" style="cursor: pointer;">{{$lang('common.expand')}}+</div>
                        </dl>
                    </div>
                </div>

                <!-- <div class="categoryCon">
                    <div :id="'category' + index" :ref="'category' + index" class="items" :class="'items-' + index"
                        v-for="(cate1, index) in goodsCategory" :key="index">
                        <h2>
                            <router-link
                                :to="{ path: '/list', query: { category_id: cate1.category_id, level: cate1.level } }"
                                target="_blank">{{ cate1.category_name }}</router-link>
                        </h2>

                        <dl v-for="(cate2, index) in cate1.child_list" :key="index">
                            <dt>
                                <router-link
                                    :to="{ path: '/list', query: { category_id: cate2.category_id, level: cate2.level } }"
                                    target="_blank">
                                    {{ cate2.category_name }}
                                </router-link>
                            </dt>
                            <dd>
                                <router-link v-for="(cate3, index) in cate2.child_list" :key="index"
                                    :to="{ path: '/list', query: { category_id: cate3.category_id, level: cate3.level } }"
                                    target="_blank">
                                    {{ cate3.category_name }}
                                </router-link>
                            </dd>
                        </dl>
                    </div>
                </div> -->

                <div class="empty-wrap" v-if="goodsCategory.length <= 0">
                    <div class="ns-text-align">{{ $lang('common.classification') }}</div>
                </div>
            </div>
            <div style="background-color: #fff;width: 1200px;margin: 0 auto;">
                <GoodsListPanel :category-id="category" :category-level="level"></GoodsListPanel>
            </div>
        </div>
        <div class="mb-category">
            <div>
                <GoodsListPanel :category-id="category" :category-level="level"></GoodsListPanel>
            </div>
        </div>
    </div>

</template>

<script>
import GoodsListPanel from '@/components/GoodsListPanel.vue'
import { tree } from "@/api/goods/goodscategory"
import { adList } from '@/api/website';

export default {
    name: "category",
    components: {
        GoodsListPanel
    },
    data: () => {
        return {
			adList: [],
			loadingAd: true,
            goodsCategory: [],
            categoryFixed: false,
            clickIndex: 0,
            loading: true,
            category: '',
            level: ''
        }
    },
    created() {
		this.getAdList();
        this.getGoodsCategory()
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll)
    },
    methods: {
		toggleIf(index) {
		      this.goodsCategory[index].is_open = !this.goodsCategory[index].is_open;
		    },
        handleCategoryClick(category) {
            this.category = category.category_id
            this.level = category.level
        },
		getAdList() {
			adList({ keyword: 'NS_PC_CATEGORY' })
				.then(res => {
					this.adList = res.data.adv_list;
					for (let i = 0; i < this.adList.length; i++) {
						if (this.adList[i].adv_url) this.adList[i].adv_url = JSON.parse(this.adList[i].adv_url);
					}
					this.loadingAd = false;
				})
				.catch(err => {
					this.loadingAd = false;
				});
		},
        // 分类列表
        getGoodsCategory() {
            tree({
                level: 3,
                template: 2
            })
                .then(res => {
                    if (res.code == 0) {
                        this.goodsCategory = res.data
                    }
                    this.loading = false
                })
                .catch(err => {
                    this.$message.error(err.message)
                    this.loading = false
                })
        },
        // 监听滚动条
        handleScroll() {
            var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            var offsetTop = document.querySelector(".newCategory").offsetTop

            if (scrollTop > offsetTop) {
                this.categoryFixed = true
            } else {
                this.categoryFixed = false
            }

            var divTopArr = []
            for (let i = 0; i < this.goodsCategory.length; i++) {
                var _top = this.$refs["category" + i][0].offsetTop

                divTopArr.push(_top)
                var _offset = scrollTop - offsetTop
                if (_offset < divTopArr[divTopArr.length - 1]) {
                    if (_offset >= divTopArr[i] && _offset < divTopArr[i + 1]) {
                        this.clickIndex = i
                    }
                } else {
                    this.clickIndex = divTopArr.length - 1
                }
            }
        },
        // 点击左侧分类
        changeCate(index, obj) {
            this.clickIndex = index
            document.querySelector(obj).scrollIntoView(true)
        }
    },
    destroyed() {
        // 离开该页面需要移除这个监听的事件，不然会报错
        window.removeEventListener("scroll", this.handleScroll)
    }
}
</script>
<style lang="scss" scoped>
.newCategory {
    width: $width;
    margin: 0 auto;
    overflow: hidden;
    background-color: #fff;

    .categoryLink {
        padding-top: 10px;
        float: left;
        position: relative;
        width: 210px;

        ul {
            width: 210px;
            padding-top: 20px;
            background-color: #FFFFFF;

            li {
                width: 200px;
                height: 30px;
                text-align: left;
                background-color: #f5f5f5;
                border-radius: 30px;
                color: #333;
                line-height: 30px;
                overflow: hidden;
                position: relative;
                cursor: pointer;
                margin: 0 auto 15px;

                &.selected {
                    background-color: $base-color;
                    background: -moz-linear-gradient(45deg, $base-color 0%, #ffffff 100%);
                    background: -webkit-gradient(45deg, color-stop(0%, $base-color), color-stop(100%, #ffffff));
                    background: -webkit-linear-gradient(45deg, $base-color 0%, #ffffff 100%);
                    background: -o-linear-gradient(45deg, $base-color 0%, #ffffff 100%);
                    background: -ms-linear-gradient(45deg, $base-color 0%, #ffffff 100%);
                    background: linear-gradient(45deg, $base-color 0%, #ffffff 100%);

                    a {
                        color: #fff;
                    }
                }

                a {
                    display: block;
                    margin-left: 30px;
                }
            }
        }
    }

    .category-fixed {
        position: fixed;
        top: 0;
        z-index: 99;
    }

    .categoryCon {
        float: left;
        padding: 0px 0 60px;
        overflow: hidden;
        width: 1190px;
        position: relative;

        .items {
            padding-left: 40px;

            h2 {
                font-size: 18px;
                font-weight: 600;
                line-height: 40px;
                margin-top: 30px;
                border-bottom: 1px solid transparent;
            }

            span {
                cursor: pointer;
            }

            dl {
                padding: 15px 0 5px;
                border-bottom: 1px solid #efefef;
                overflow: hidden;

                dt {
                    padding-right: 20px;
                    width: 132px;
                    position: relative;
                    background: #fff;
                    float: left;

                    span {
                        float: left;
                        max-width: 132px;
                        // overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        font-weight: 600;
                    }
                }

                dd {
                    float: left;
                    width: 858px;

                    span {
                        color: #666;
                        float: left;
                        padding: 0 12px;
                        margin: 0 0 10px -1px;
                        border-left: 1px solid #e0e0e0;
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 1025px) {
    .mb-category {
        display: none;
    }
}

/* 适用于手机设备 */
@media only screen and (max-width: 600px) {
    .pc-category {
        display: none;
    }
}
</style>
