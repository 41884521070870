const state = {
    isMobile: false, // 是否移动端
  };
  
  const mutations = {
    SET_IS_MOBILE(state, isMobile) {
      state.isMobile = isMobile;
    },
  };
  
  const actions = {
    // 初始化设备检测
    initDevice({ commit }) {
      const isMobile = isMobileDevice();
      commit('SET_IS_MOBILE', isMobile);
  
      // 监听窗口大小变化
      addWindowResizeHandler(() => {
        const isMobile = isMobileDevice();
        commit('SET_IS_MOBILE', isMobile);
      });
      console.log('isMobile',isMobile);
      
    },
  };
  
  const getters = {
    isMobile: (state) => state.isMobile,
  };
  
  // 判断是否为移动端
  function isMobileDevice() {
    const userAgent = navigator.userAgent.toLowerCase();
    const mobileKeywords = ['mobile', 'android', 'iphone', 'ipad', 'windows phone'];
    return mobileKeywords.some((keyword) => userAgent.includes(keyword)) || window.innerWidth <= 768;
  }
  
  // 添加窗口大小变化监听器
  function addWindowResizeHandler(handler) {
    const oldHandler = window.onresize;
    if (typeof window.onresize !== 'function') {
      window.onresize = handler;
    } else {
      window.onresize = function (event) {
        if (oldHandler) {
          oldHandler.call(window, event);
        }
        handler();
      };
    }
  }
  
  export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
  };