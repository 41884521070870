<template>
    <div>
        <div class="tile-title"><h3 style="color: rgb(40, 147, 255)">Tile</h3></div>
        <div class="grid-container">
            <div class="grid-item" v-for="(item, index) in data.value.goodsList.value.list" :key="index" :title="item.goods_name" @click="goSku(item.sku_id)">
                <div class="img-wrap"><img alt="商品图片" :src="$img(item.sku_image)" @error="imageError(index)" /></div>
                <h3>{{ item.goods_name }}</h3>
                <p class="desc">{{ item.introduction }}</p>
                <p class="price">
                    <span class="num">${{ item.discount_price }}</span>
                    <del>${{ item.market_price }}</del>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex"
    export default {
        data() {
            return {}
        },
        props: {
            data: {
                type: Object
            }
        },
        computed: {
            ...mapGetters(["defaultGoodsImage"])
        },
        methods: {
            goSku(skuId) {
                this.$router.push("/sku-" + skuId)
            },
            imageError(index) {
                this.data.value.goodsList.value.list[index].sku_image = this.defaultGoodsImage
            }
        }
    }
</script>

<style scoped lang="scss">
    .grid-container {
        width: 100%;
        display: grid;
        max-width: 1200px;
        grid-template-columns: repeat(4, 1fr);
        // border: 1px solid #dbe3ef;
        box-sizing: border-box;
    }

    .grid-item {
        border: 1px solid #dbe3ef;
        margin-right: -1px; /* 消除右边框重叠 */
        margin-bottom: -1px; /* 消除下边框重叠 */
        padding: 20px;
        box-sizing: border-box;
        background: #fff;
        position: relative;
        .img-wrap {
            width: 160px;
            height: 160px;
            margin: 0 auto 18px;
            text-align: center;
            line-height: 160px;
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
        h3 {
            font-size: 12px;
            text-align: center;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical !important;
            margin: 5px 15px;
            cursor: pointer;
            &:hover{
                color: $base-color;
                text-decoration: underline;
            }
        }
        .desc {
            margin: 0 30px 10px;
            height: 20px;
            font-size: 12px;
            color: #b0b0b0;
            text-align: center;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
        .price {
            margin: 0 10px 14px;
            text-align: center;
            color: $base-color;
            del {
                margin-left: 0.5em;
                color: #b0b0b0;
            }
        }
    }
    /* 消除最后一列的右边距 */
    .grid-item:nth-child(4n) {
        margin-right: 0;
    }

    /* 消除最后一行的下边距 */
    .grid-item:nth-last-child(-n + 4) {
        margin-bottom: 0;
    }
    .tile-title {
        width: 100%;
        line-height: 30px;
        padding: 12px 20px;
        border: 1px solid #dbe3ef;
        box-sizing: border-box;
        background-color: #fff;
        border-top-color: rgb(40, 147, 255);
        border-top-width: 2px;
        border-bottom: none;
    }
    /* 移动端适配：屏幕宽度小于 768px 时，一排显示 2 个 */
@media (max-width: 768px) {
    .grid-container {
        grid-template-columns: repeat(2, 1fr); /* 一排 2 个 */
    }

    /* 调整移动端下的样式 */
    .grid-item {
        padding: 10px; /* 减少内边距 */
        .img-wrap {
            width: 120px; /* 缩小图片容器 */
            height: 120px;
            line-height: 120px;
        }
        h3 {
            font-size: 14px; /* 增大字体 */
            margin: 5px 10px; /* 调整边距 */
        }
        .desc {
            margin: 0 10px 10px; /* 调整边距 */
        }
        .price {
            margin: 0 5px 10px; /* 调整边距 */
        }
    }

    /* 消除最后一列的右边距 */
    .grid-item:nth-child(2n) {
        margin-right: 0;
    }

    /* 消除最后一行的下边距 */
    .grid-item:nth-last-child(-n + 2) {
        margin-bottom: 0;
    }
}
</style>
</style>
