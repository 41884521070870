<template>
    <div class="notice-wrap about-us" :class="[isMobile ? 'mobile' : 'pc']">
        <!-- <div class="index-wrap" style="width: 1200px;margin: 0 auto;">
			<el-carousel height="400px" v-loading="loadingAd">
				<el-carousel-item v-for="item in adList" :key="item.adv_id"><el-image :src="$img(item.adv_image)" fit="cover" @click="$router.pushToTab(item.adv_url.url)" /></el-carousel-item>
			</el-carousel>
		</div> -->
        <el-breadcrumb separator="/" class="path" >
            <el-breadcrumb-item :to="{ path: '/' }" class="path-home">
                <i class="n el-icon-s-home"></i>
                {{ $lang("common.home") }}
            </el-breadcrumb-item>
            <el-breadcrumb-item class="path-help">{{ $lang("title") }}</el-breadcrumb-item>
        </el-breadcrumb>

        <div class="notice-detil" v-loading="loading" >
            <!-- <div class="notice-info">
				<div class="title">{{ info.title }}</div>
				<div class="time">{{ $util.timeStampTurnTime(info.create_time) }}</div>
			</div> -->
            <div class="content" v-html="info.about"></div>
        </div>
        <!-- <div class="" style="width: 1200px;height: 350px;margin-top: 50px;margin: 0 auto;">
			<el-col :span="11" class="" style="margin-left: 300px;">
				<el-form :model="formData" :rules="messageRules"
					ref="ruleForm">
					<el-form-item prop="name">
						<el-input v-model="formData.name" placeholder="Please type in your name">
							<template slot="prepend">
								<i class="iconfont iconzhanghao"></i>
							</template>
						</el-input>
					</el-form-item>
					<el-form-item prop="phone">
						<el-input v-model="formData.phone" placeholder="Please type in your phone">
							<template slot="prepend">
								<i class="iconfont iconshouji-copy"></i>
							</template>
						</el-input>
					</el-form-item>
					<el-form-item prop="email">
						<el-input v-model="formData.email" placeholder="Please type in your email">
							<template slot="prepend">
								<i class="iconfont iconyouxiang"></i>
							</template>
						</el-input>
					</el-form-item>
					<el-form-item prop="message">
						<el-input type="textarea" v-model="formData.message" placeholder="Please enter a message"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" class="rule-button" @click="messageSub('ruleForm')">
							submit</el-button>
					</el-form-item>
				</el-form>
			</el-col>
			
		</div> -->
    </div>
</template>

<script>
    import { aboutUs, aboutUsMessage } from "@/api/cms/notice"
    import { adList } from "@/api/website"
    import { mapGetters, mapActions } from "vuex"
    export default {
        name: "about_us",
        components: {},
        data: () => {
            var isPhone = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error("Mobile phone number cannot be empty"))
                } else {
                    const reg = /^1[3|4|5|6|7|8|9][0-9]{9}$/

                    if (reg.test(value)) {
                        callback()
                    } else {
                        callback(new Error("please enter a valid phone number"))
                    }
                }
            }
            return {
                adList: [],
                loadingAd: true,
                info: {},
                loading: true,
                formData: {
                    name: "",
                    phone: "",
                    email: "",
                    message: ""
                }, // 表单数据
                messageRules: {
                    name: [
                        {
                            required: true,
                            name: "Please type in your name",
                            trigger: "blur"
                        }
                    ],
                    phone: [
                        {
                            required: true,
                            validator: isPhone,
                            trigger: "blur"
                        }
                    ],
                    email: [
                        {
                            required: true,
                            email: "Please type in your email",
                            trigger: "blur"
                        }
                    ],
                    message: [
                        {
                            required: true,
                            message: "Please enter a message",
                            trigger: "blur"
                        }
                    ]
                }
            }
        },
        computed: {
            ...mapGetters("device", ["isMobile"]) // 获取 isMobile 状态
        },
        created() {
            this.getAdList()
            this.getDetail()
        },
        methods: {
            messageSub(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        var data = {
                            name: this.formData.name,
                            phone: this.formData.phone,
                            email: this.formData.email,
                            message: this.formData.message
                        }

                        aboutUsMessage(data)
                            .then((res) => {
                                if (res.code >= 0) {
                                    this.$message({
                                        message: "Message successfully！",
                                        type: "success"
                                    })
                                    setTimeout(() => {
                                        location.reload()
                                    }, 2000)
                                } else {
                                    this.$message({
                                        message: res.message,
                                        type: "warning"
                                    })
                                }
                            })
                            .catch((err) => {
                                this.$message.error(err.message)
                            })
                    } else {
                        return false
                    }
                })
            },
            getAdList() {
                adList({ keyword: "NS_PC_ABOUT_US" })
                    .then((res) => {
                        this.adList = res.data.adv_list
                        for (let i = 0; i < this.adList.length; i++) {
                            if (this.adList[i].adv_url) this.adList[i].adv_url = JSON.parse(this.adList[i].adv_url)
                        }
                        this.loadingAd = false
                    })
                    .catch((err) => {
                        this.loadingAd = false
                    })
            },
            getDetail() {
                aboutUs()
                    .then((res) => {
                        if (res.data) {
                            this.info = res.data
                            this.loading = false
                        } else {
                            this.$router.push({ path: "/" })
                        }
                    })
                    .catch((err) => {
                        this.loading = false
                        this.$message.error(err.message)
                    })
            }
        }
    }
</script>
<style lang="scss" scoped>
    .web {
        width: 1200px;
        margin: 0 auto;
    }
    .notice-detil {
        background-color: #ffffff;
        min-height: 300px;
        padding: 10px;
		margin: 0 auto;
        .title {
            text-align: center;
            font-size: 18px;
            margin: 10px 0;
        }
        .time {
            text-align: center;
            color: #838383;
            margin-bottom: 17px;
        }

        .notice-info {
            margin: 0 43px;
            border-bottom: 1px dotted #e9e9e9;
        }
        .content {
            padding-top: 10px;
        }
    }
    .path {
        padding: 15px 0;
		margin: 0 auto;
    }


	.about-us.pc{
		.path{
			width: 1200px; 
		}
		.notice-detil{
			width: 1200px;
		}
		.content {
            margin: 0 25px;
        }
	}
	.about-us.mobile{
	    
	}
</style>
