export const lang = {
	// title adalah judul setiap halaman
	title: 'Detail Produk',
	noticeTag: 'Peringatan',
	noticeValue: 'Harga yang ditampilkan adalah harga contoh, untuk penawaran detail silakan hubungi penjual toko',
	shop: 'Toko',
	shopSeller: 'Penjual Toko',
	shopSellerPhone: 'Telepon Penjual',
	count: 'Jumlah',
	stock: 'Stok',
	buyNow: 'Beli Sekarang',
	addToCart: 'Tambahkan ke Keranjang',
	outOfStock: 'Stok Habis',
	goodsDetail: 'Detail Produk',
	goodsProperty: 'Properti Produk',
	goodsComment: 'Komentar Produk',
	hotSales: 'Daftar Terlaris 24 Jam',
	recommend: 'Rekomendasi Produk Serupa',
	sales: 'Popularitas Penjualan',
	views: 'Dilihat',
	collect: 'Popularitas Koleksi',
	brand: 'Informasi Merek',
	findHots: 'Lihat Produk Terlaris',
	rank: 'Peringkat Dilihat',
	collected: 'Produk Dikoleksi',
	brandIndex: 'Halaman Utama Merek',
}