export const lang = {
	//title为每个页面的标题
	title: 'تفاصيل المنتج',
	noticeTag: 'تذكير',
	noticeValue: 'سعر الصفحة هو عرض الأسعار ، تفاصيل العرض يرجى الاتصال متجر المبيعات',
	shop: 'مخزن',
	shopSeller: 'مبيعات المتجر',
	shopSellerPhone: 'مبيعات الهاتف',
	count: 'الكمية',
	stock: 'جرد',
	buyNow: 'شراء على الفور',
	addToCart: 'الانضمام إلى عربة التسوق',
	outOfStock: 'نقص المخزون',
	goodsDetail: 'السلع بالتفصيل',
	goodsProperty: 'خصائص السلع',
	goodsComment: 'استعراض السلع',
	hotSales: 'قائمة 24 ساعة',
	recommend: 'السلع المماثلة الموصى بها',
	sales: 'مبيعات شعبية',
	views: 'تصفح السلع',
	collect: 'جمع شعبية',
	brand: 'معلومات العلامة التجارية',
	findHots: 'عرض الساخن بيع',
	rank: 'تصفح الترتيب',
	collected: 'جمع السلع',
	brandIndex: 'العلامة التجارية الرئيسية',
}
