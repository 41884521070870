<template>
    <div class="carousel-container" @touchstart.stop="startTouch" @touchmove.stop="moveTouch" @touchend.stop="endTouch">
        <div class="carousel-wrapper" :style="{ transform: `translateX(-${currentIndex * 100}%)` }">
            <div v-for="(image, index) in dataList" :key="index" class="carousel-item">
                <img v-if="image.type == '__img__'" :src="$img(image.url)" alt="Carousel Image" />
                <video v-else-if="image.type == '__video__' && currentIndex == index" controls :src="$img(image.url)" alt="Carousel Video"  style="width:100%;height:90%"/>
            </div>
        </div>
        <div class="carousel-controls">
            <button @click="prevSlide" :disabled="currentIndex === 0">Prev</button>
            <span>{{ currentIndex + 1 }}/{{ dataList.length }}</span>
            <button @click="nextSlide" :disabled="currentIndex === dataList.length - 1">Next</button>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            goodsSkuDetail: {
                type: Object,
                required: true
            },
            skuImages: {
                type: Array,
                required: true
            },
        },
        data() {
            return {
                currentIndex: 0,
                startX: 0,
                currentX: 0,
                images: [
                    // 'https://dwz.cn/2MMW2jpN', // 替换为你的图片链接，这里只是示例
                    // 添加更多图片链接...
                    'https://img0.baidu.com/it/u=2191392668,814349101&fm=253&fmt=auto&app=138&f=JPEG?w=800&h=1399',
                    'https://img1.baidu.com/it/u=787132584,3819075437&fm=253&fmt=auto&app=120&f=JPEG?w=500&h=750'
                ],
                dataList:[],
                isDragging: false,
            };
        },
        watch: {
           goodsSkuDetail(newVal) {
                console.log('goodsSkuDetail has been updated:', newVal);
                const imgList = []
                newVal.sku_images.forEach(item => {
                    imgList.push({
                        type:'__img__',
                        url:this.$img(item)
                    })
                })
                if(newVal.video_url !== ''){
                    imgList.push({
                        type:'__video__',
                        url:this.$img(newVal.video_url)
                    })
                }
                this.dataList = imgList
                // 您可以在这里对newVal进行任何需要的处理
                // console.log("json: ", newVal.sku_spec_format)
            },
           /*  skuImages(newVal) {
                console.log('skuImages has been updated:', newVal);
                // 您可以在这里对newVal进行任何需要的处理
            }*/
        },
        mounted() {
            console.log("111: ",this.picZoomUrl)
        },
        methods: {
            startTouch(e) {
                this.startX = e.touches[0].pageX;
                this.isDragging = true;
            },
            moveTouch(e) {
                if (!this.isDragging) return;
                this.currentX = e.touches[0].pageX;
            },
            endTouch() {
                if (!this.isDragging ||  this.currentX == 0) return;
                const diffX = this.currentX - this.startX;
                if (diffX > 50) {
                    this.prevSlide();
                } else if (diffX < -50) {
                    this.nextSlide();
                }
                 this.currentX =0
                this.isDragging = false;
            },
            prevSlide() {
                if (this.currentIndex > 0) {
                    this.currentIndex -= 1;
                }
            },
            nextSlide() {
                if (this.currentIndex < this.dataList.length - 1) {
                    this.currentIndex += 1;
                }
            },
        },
    };
</script>

<style scoped>
    .carousel-container {
        position: relative;
        /*width: 100%;*/
        height: 100vw;
        overflow: hidden;
    }

    .carousel-wrapper {
        display: flex;
        transition: transform 0.3s ease;
    }

    .carousel-item {
        min-width: 100%;
        box-sizing: border-box;
        width: 100vw; /* 宽度设置为视口宽度 */
        height: 100vw; /* 高度也设置为视口宽度，以形成正方形 */
    }

    .carousel-item img {
        width: 100%;
        display: block;
    }

    .carousel-controls {
        position: absolute;
        bottom: 10px;
        left: 0;
        right: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
        background: rgba(0, 0, 0, 0.5);
        color: white;
    }

    button {
        border: none;
        background: transparent;
        color: white;
        font-size: 16px;
        cursor: pointer;
    }

    button:disabled {
        color: gray;
        cursor: not-allowed;
    }
</style>