export const lang = {
	// title là tiêu đề của mỗi trang
	title: 'Chi tiết sản phẩm',
	noticeTag: 'Thông báo',
	noticeValue: 'Giá hiển thị trên trang là giá tham khảo, vui lòng liên hệ với nhân viên bán hàng của cửa hàng để biết báo giá chi tiết',
	shop: 'Cửa hàng',
	shopSeller: 'Nhân viên bán hàng',
	shopSellerPhone: 'Điện thoại bán hàng',
	count: 'Số lượng',
	stock: 'Kho hàng',
	buyNow: 'Mua ngay',
	addToCart: 'Thêm vào giỏ hàng',
	outOfStock: 'Hết hàng',
	goodsDetail: 'Chi tiết sản phẩm',
	goodsProperty: 'Thuộc tính sản phẩm',
	goodsComment: 'Đánh giá sản phẩm',
	hotSales: 'Bảng xếp hạng bán chạy 24 giờ',
	recommend: 'Sản phẩm tương tự đề xuất',
	sales: 'Lượt bán',
	views: 'Lượt xem',
	collect: 'Lượt yêu thích',
	brand: 'Thông tin thương hiệu',
	findHots: 'Xem sản phẩm bán chạy',
	rank: 'Xếp hạng lượt xem',
	collected: 'Sản phẩm đã yêu thích',
	brandIndex: 'Trang chủ thương hiệu',
}