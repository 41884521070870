<template>
    <div>
<!--        <img src="@/assets/index_img/首页_14.jpg">
        <img src="@/assets/index_img/首页_17.jpg">
        <img src="@/assets/index_img/首页_featured_product.jpg">
        <img src="@/assets/index_img/首页_23.jpg">-->
        <!--<img src="@/assets/index_img/首页_26.jpg">
        <img src="@/assets/index_img/首页_32.jpg">
        <img src="@/assets/index_img/首页_38.jpg">
        <img src="@/assets/index_img/首页_44.jpg">
        <img src="@/assets/index_img/首页_50.jpg">
        <img src="@/assets/index_img/首页_56.jpg">-->
        <!--<img src="@/assets/index_img/首页_62.jpg">
        <img src="@/assets/index_img/首页_65.jpg">-->

    </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
    name: 'index-main',
    props: {
        data: {
            type: Object
        }
    },
    data() {
        return {};
    },
    created() {},
    computed: {
        ...mapGetters(['defaultGoodsImage'])
    },
    methods: {
        goSku(skuId) {
            this.$router.push('/sku-' + skuId);
        },
        imageError(index) {
            this.data.value.goodsList.value.list[index].sku_image = this.defaultGoodsImage;
        }
    }
}
</script>